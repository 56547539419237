import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const { element } = this.context;
    this.clearResponsiveTables(element);
  }

  clearResponsiveTables(element) {
    element.querySelectorAll('.table-responsive').forEach((table) => {
      if (table.querySelector('.dropdown-menu')) {
        table.classList.add('clear-dropdown');
      }
    });
  }
}

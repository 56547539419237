// Entry point for the build script in your package.json

import "@hotwired/turbo-rails";
import "@rails/request.js";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@stimulus/polyfills';
import 'whatwg-fetch';
import Trix from 'trix';

import 'bootstrap/bootstrap_custom';

import "./channels"
import './controllers';
import closeModal from 'lib/utils/close-modal';
import handleQuestionClick from 'lib/question';

require('@rails/activestorage').start();
require('@rails/actiontext');
require('@rails/actioncable');
require('chart.js');

window.Trix = Trix;

document.addEventListener('turbo:load', () => {
  closeModal();
  handleQuestionClick();
});

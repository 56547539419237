import { Controller } from '@hotwired/stimulus';

// Handle file uploads.
// Initially implemented for the questions feature.
// Allows files to be inserting into the Trix editor by clicking a button that's
// separate from Trix's toolbar.

export default class extends Controller {

  static targets = ['fileInput'];

  upload(event) {
    event.preventDefault();
    const trixElem = document.querySelector("trix-editor");
    const selectedFiles = [...this.fileInputTarget.files];
    selectedFiles.forEach(file => trixElem.editor.insertFile(file))
  }
}

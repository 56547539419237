import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["balance", "form"]

  connect() {
    let startingBalanceFields = Array.from(this.element.getElementsByClassName('starting-balance-field'));

    startingBalanceFields.forEach((element) => {
      element.addEventListener('change', this.validateForm.bind(this));
    });

    document.getElementById('starting-balances-form')
      .addEventListener('submit', this.submitForm.bind(this));
  }

  submitForm(event) {
    const isValid = this.validateForm();

    if (!isValid) {
      event.preventDefault();
      return false;
    }
  }

  validateForm(event) {
    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    const requiredFields = this.formTarget.querySelectorAll(".starting-balance-field");
    const sum = [...requiredFields].reduce((totalSum, field) => {
      return totalSum + parseFloat(field.value);
    }, 0.0);

    [...this.balanceTarget.querySelectorAll(".balance-display-field")].forEach((field) => {
      field.style.display = "none";
    });

    if (sum < 0.005 && sum > -0.005) {
      this.balanceTarget.querySelector(".balanced").style.display = "inline";
      return true;
    } else {
      this.balanceTarget.querySelector(".unbalanced").style.display = "inline";
      this.balanceTarget.querySelector(".unbalanced-amount").innerHTML = formatter.format(sum);
      return false;
    }
  }
}

import NestedLineController from './nested_line_controller'

export default class extends NestedLineController {
  static targets = [ "mutableAmount", "immutableAmount", "remainingAmount" ];

  connect() {
    this.updateRemainingAmount()
  }

  handleAmountChange() {
    this.updateRemainingAmount()
  }

  handleRemovedLine(event) {
    this.remove(event)
    this.updateRemainingAmount()
  }

  handleAddLine(event) {
    this.add(event)
    this.applyRemainingAmountToNewLine()
    this.updateRemainingAmount()
  }

  applyRemainingAmountToNewLine() {
    let emptyLines = this.mutableAmountTargets.filter( a => a.value === '')
    let availableRemainingAmount = this.remainingAmount() !== 0

    if (emptyLines.length > 0) {
      emptyLines.forEach(a => {
        if (availableRemainingAmount) {
          a.value = this.microsToFloat(-this.remainingAmount())
          availableRemainingAmount = false
        } else {
          a.value = 0
        }
      })
    }
  }

  updateRemainingAmount() {
    this.remainingAmountTarget.innerHTML = `$${this.microsToFloat(-this.remainingAmount())}`
  }

  isBalanced() {
    return this.remainingAmount() === 0
  }

  remainingAmount() {
    return (
      this.mutableAmounts().reduce((result, line) => (line + result), 0) -
        this.immutableAmounts().reduce((result, line) => (line + result), 0)
    )
  }

  microsToFloat(value) {
    return ((( parseInt(value) / 10000 )) / 100.0).toFixed(2)
  }

  valueToMicros(value){
    return (parseInt((parseFloat(value) * 100).toFixed()) * 10000)
  }

  mutableAmounts() {
    return this.mutableAmountTargets.map(l => this.valueToMicros(l.value || 0))
  }

  immutableAmounts() {
    return this.immutableAmountTargets.map(l => parseInt(l.dataset.amountMicros || 0))
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dateSelect", "startDate", "endDate"]

  connect() {
    this.dateSelectTarget.classList.remove("d-none")
  }

  disconnect() {
    this.dateSelectTarget.classList.add("d-none")
  }

  updateDateValues() {
    if (this.dateSelectTarget.value) {
      let [startDate, endDate] = this.dateSelectTarget.value.split(",")

      if (this.hasEndDateTarget) {
        this.endDateTarget.value = endDate
      }
      if (this.hasStartDateTarget) {
        this.startDateTarget.value = startDate
      }
    }
  }

  clearDateSelect() {
    this.dateSelectTarget.value = ""
  }
}

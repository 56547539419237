import React from 'react';
import ReactDOM from 'react-dom';

const didAlreadyRenderComponentInContainer = (container: HTMLElement) => container.dataset.rendered;

export const renderComponent = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: React.ComponentType<any>,
  componentNameOrGetContainerFn: string | (() => HTMLElement | null),
) => {
  const getContainer = typeof componentNameOrGetContainerFn === 'function'
    ? componentNameOrGetContainerFn
    : (() => document.querySelector(`[data-react-component=${componentNameOrGetContainerFn}]`) as HTMLElement);

  const render = () => {
    const containerElement = getContainer()!;
    const propsJSON = containerElement.dataset.props;
    const props = propsJSON ? JSON.parse(propsJSON) : {};

    containerElement.dataset.rendered = 'true';

    ReactDOM.render(
      <Component {...props} />,
      containerElement,
    );
  };

  const isAlreadyRendered = () => didAlreadyRenderComponentInContainer(getContainer()!);

  if (!document.documentElement.hasAttribute('data-turbo-preview')) {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', render, { once: true });
    } else if (!isAlreadyRendered()) {
      render();
    } else {
      return;
    }

    document.addEventListener('turbo:before-cache', () => {
      const containerElement = getContainer();

      if (containerElement) {
        ReactDOM.unmountComponentAtNode(containerElement);
        delete containerElement.dataset.rendered;
      }
    }, { once: true });
  }
};

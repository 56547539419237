import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  copyToClipboard(str) {
    // https://www.30secondsofcode.org/articles/s/copy-text-to-clipboard-with-javascript
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(str);
    } else {
      const el = document.createElement("textarea");
      el.value = str;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    }
  }

  copy(e) {
    this.copyToClipboard(e.target.dataset.value);
    e.preventDefault();
  }
}

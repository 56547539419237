export const BootstrapSpinnerHTML = (text = 'Loading...') => (
  `<span class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>${text}`
);

const DEFAULT_SPINNER_HTML = BootstrapSpinnerHTML();

/* eslint-disable no-param-reassign */
export const addLoaderToButton = (
  buttonElem: HTMLButtonElement | HTMLInputElement,
  spinnerHTML = DEFAULT_SPINNER_HTML,
) => {
  buttonElem.disabled = true;
  buttonElem.setAttribute('data-original-content', buttonElem.textContent!);
  buttonElem.innerHTML = spinnerHTML;
};

import { Controller } from "@hotwired/stimulus"

/**
 * Nested Line Stimulus Controller
 *
 * This controller is a modified version of the Stimulus Rails Nested Form controller.
 *
 * https://github.com/stimulus-components/stimulus-rails-nested-form
 *
 * It makes a few changes:
 *
 * Supports Multiple Templates
 *
 * This behavior is taken from the "Add support many nested forms" pull request]
 * Instead of supporting multiple nested forms, we need to support multiple types of records using named templates.
 * Instead of adding the different records to different targets, we want to add them to the same target.
 *
 * https://github.com/stimulus-components/stimulus-rails-nested-form/pull/5
 *
 * Remove NEW_RECORD Support
 *
 * Spark Journal Lines do not post a line id for each record, since the liens are recreated on each adjustment.
 * Since Spark Journal Lines do not use the typical ActiveRecord approach to persisting, the approach taken can be simplified by removing NEW_RECORD completely.
 *
 * Simplified Remove
 *
 * The remove method now always removes the DOM element, where the Stimulus Rails Nested Form controller uses a hidden input (_destroy) to indicate a record needs to be removed.
 * Similar to the NEW_RECORD removal, this feature is not needed because the Spark Journal Lines are recreated on every adjustment.
 *
 */

export default class extends Controller {
  static targets = ["insert", "template"]
  static values = {
    wrapperSelector: {
      type: String,
      default: ".nested-line-wrapper"
    }
  }

  add (e) {
    e.preventDefault()

    const model = e.params["model"]
    const newRecordIdRegex = new RegExp(`${"NEW_" + model.toUpperCase() + "_ID"}`, "g")
    const newRecordIdReplacement = new Date().getTime().toString()

    const templateTarget = this.templateTargets.find(el => el.dataset.model === model)
    const content = templateTarget.innerHTML.replace(newRecordIdRegex, newRecordIdReplacement)

    this.insertTarget.insertAdjacentHTML("beforebegin", content)
  }

  remove (e) {
    e.preventDefault()

    e.target.closest(this.wrapperSelectorValue).remove()
  }
}

/**
 * Here is an example of how to use this controller:
 *
 *     <form data-controller="nested-line">
 *         <button type="button" data-action="nested-line#add" data-nested-line-model-param="foo">Add Foo</button>
 *         <button type="button" data-action="nested-line#add" data-nested-line-model-param="bar">Add Bar</button>
 *         <button type="button" data-action="nested-line#add" data-nested-line-model-param="baz">Add Baz</button>
 *
 *         <template data-nested-line-target="template" data-model="foo">
 *             <div class="nested-line-wrapper">
 *                 <button type="button" data-action="nested-line#remove" title="Remove Foo">X</button>
 *                 <label for="foo-NEW_FOO_ID">Foo NEW_FOO_ID</label>
 *                 <input id="foo-NEW_FOO_ID" name="entry[line_attributes][NEW_FOO_ID][body]" value="Foo NEW_FOO_ID" />
 *             </div>
 *         </template>
 *           <template data-nested-line-target="template" data-model="bar">
 *             <div class="nested-line-wrapper">
 *                 <button type="button" data-action="nested-line#remove" title="Remove Bar">X</button>
 *                 <label for="bar-NEW_BAR_ID">Bar NEW_BAR_ID</label>
 *                 <input id="bar-NEW_BAR_ID" name="entry[line_attributes][NEW_BAR_ID][body]" value="Bar NEW_BAR_ID" />
 *             </div>
 *         </template>
 *           <template data-nested-line-target="template" data-model="baz">
 *             <div class="nested-line-wrapper">
 *                 <button type="button" data-action="nested-line#remove" title="Remove Baz">X</button>
 *                 <label for="baz-NEW_BAZ_ID">Baz NEW_BAZ_ID</label>
 *                 <input id="baz-NEW_BAZ_ID" name="entry[line_attributes][NEW_BAZ_ID][body]" value="Baz NEW_BAZ_ID" />
 *             </div>
 *         </template>
 *
 *         <div class="nested-line-wrapper">
 *             <button type="button" data-action="nested-line#remove" title="Remove Foo">X</button>
 *             <label for="foo-1">Foo 1</label>
 *             <input id="foo-1" name="entry[line_attributes][0][body]" value="Foo 1" />
 *         </div>
 *         <div class="nested-line-wrapper">
 *             <button type="button" data-action="nested-line#remove" title="Remove Bar">X</button>
 *             <label for="bar-1">Bar 1</label>
 *             <input id="bar-1" name="entry[line_attributes][1][body]" value="Bar 1" />
 *         </div>
 *         <div class="nested-line-wrapper">
 *             <button type="button" data-action="nested-line#remove" title="Remove Baz">X</button>
 *             <label for="baz-1">Baz 1</label>
 *             <input id="baz-1" name="entry[line_attributes][2][body]" value="Baz 1" />
 *         </div>
 *         <div class="nested-line-wrapper">
 *             <button type="button" data-action="nested-line#remove" title="Remove Bar">X</button>
 *             <label for="bar-2">Bar 2</label>
 *             <input id="bar-2" name="entry[line_attributes][3][body]" value="Bar 2" />
 *         </div>
 *         <div class="nested-line-wrapper">
 *             <button type="button" data-action="nested-line#remove" title="Remove Foo">X</button>
 *             <label for="foo-2">Foo 2</label>
 *             <input id="foo-2" name="entry[line_attributes][4][body]" value="Foo 2" />
 *         </div>
 *
 *         <div data-nested-line-target="insert"></div>
 *     </form>
 */

import { Controller } from '@hotwired/stimulus'
import jsonview from '@pgrabovets/json-view'

export default class extends Controller {
  static targets = ["json", "data"]

  connect() {
    // get json data
    const jsonData = this.dataTarget.dataset.json
    // create json tree object
    const tree = jsonview.create(jsonData)

    // render tree into dom element
    jsonview.render(tree, this.jsonTarget);
    // expand tree
    jsonview.expand(tree);
  }

}
import { camelCase, mapKeys } from 'lodash';

/** https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript */
export const withoutDiacritics = (str: string): string => (
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
);

export const camelizeKeys = <T extends Record<string, unknown>>(obj: T) => (
  mapKeys<T>(obj, (_value, key) => camelCase(key))
);

export const capitalizeFirstLetter = (str: string) => str.slice(0, 1).toUpperCase() + str.slice(1);

import PrefetchSelectController from "./prefetch_select_controller"
import TomSelect from "tom-select"
import { FetchRequest } from "@rails/request.js"

export default class extends PrefetchSelectController {
  static values = {
    settings: Object,
    url: String,
    entityPath: String,
  }

  connect() {
    let loadSettings = {
      load: (query, callback) => this.search(query, callback),
    };
    let settings = Object.assign(this.defaultSettings(), this.settingsValue, loadSettings);
    this.tomSelect = new TomSelect(this.element, settings);
    this.tomSelect.on('type', (value) => this.typeHandler(value))
    this.tomSelect.on('item_add', (item) => this.selectHandler(item))
  }

  typeHandler(value) {
    const existingOptions = this.findExistingOptions(value)

    if (existingOptions.length == 0) {
      this.updateNewCustomerOption(value)
      this.updateNewVendorOption(value)
    } else {
      if (existingOptions.some(option => option.optgroup === 'Customers')) {
        this.removeNewCustomerOption();
      } else {
        this.updateNewCustomerOption(value)
      }
      if (existingOptions.some(option => option.optgroup == 'Vendors')) {
        this.removeNewVendorOption();
      } else {
        this.updateNewVendorOption(value)
      }
    }

    this.tomSelect.refreshOptions(true);
  }

  async selectHandler(item) {
    if (item === 'new-customer' || item === 'new-vendor') {
      this.tomSelect.clear(true);

      const selectedOption = this.tomSelect.options[item]
      const itemMatches = selectedOption.text.match(/Add '(.*)' as (customer|vendor)/)
      const name = itemMatches[1];
      const model = itemMatches[2].toLowerCase();

      let newOption;

      if (model == "customer") {
        const newCustomer = await this.createNewCustomer(name);
        if (typeof newCustomer != 'undefined') {
          newOption = { value: `customer-${newCustomer['value']}`, text: newCustomer['text'], optgroup: 'Customers' }
        }
      } else if (model == "vendor") {
        const newVendor = await this.createNewVendor(name);
        if (typeof newVendor != 'undefined') {
          newOption = { value: `vendor-${newVendor['value']}`, text: newVendor['text'], optgroup: 'Vendors' }
        }
      }

      if (typeof newOption != 'undefined') {
        this.tomSelect.addOption(newOption)
        this.tomSelect.addItem(newOption['value'], true)
      }
      this.clearNewOptions();
      this.tomSelect.refreshOptions(false)
    }
  }

  findExistingOptions(value) {
    const lowerValue = value.toLowerCase();
    return Object.values(this.tomSelect.options).filter(option => option.text.toLowerCase() === lowerValue)
  }

  updateNewCustomerOption(value) {
    this.tomSelect.addOption({ value: 'new-customer', text: `Add new customer`, optgroup: 'Customers' })
    this.tomSelect.updateOption('new-customer', { value: 'new-customer', text: `Add '${value}' as customer`, optgroup: 'Customers' })
  }

  removeNewCustomerOption() {
    this.tomSelect.removeOption('new-customer');
  }

  updateNewVendorOption(value) {
    this.tomSelect.addOption({ value: 'new-vendor', text: `Add new vendor`, optgroup: 'Vendors' })
    this.tomSelect.updateOption('new-vendor', { value: 'new-vendor', text: `Add '${value}' as vendor`, optgroup: 'Vendors' })
  }

  removeNewVendorOption() {
    this.tomSelect.removeOption('new-vendor');
  }

  clearNewOptions() {
    this.removeNewCustomerOption();
    this.removeNewVendorOption();
  }

  async createNewVendor(name) {
    const body = { "vendor": { name: name } }
    const vendorUrl = `${this.entityPathValue}/vendors`
    return await this.callApiCreate(vendorUrl, body);
  }

  async createNewCustomer(name) {
    const body = { "customer": { name: name } }
    const customerUrl = `${this.entityPathValue}/customers`
    return await this.callApiCreate(customerUrl, body);
  }

  async callApiCreate(url, bodyObject) {
    const body = JSON.stringify(bodyObject)
    const request = new FetchRequest(
      "POST",
      url,
      {
        body,
        responseKind: "json",
      }
    );
    const response = await request.perform();
    if (response.ok) {
      const body = await response.json;
      return { value: body["id"], text: body["name"] };
    } else {
      return undefined;
    }
  }
}

const handleQuestionClick = () => {
  const questions = Array.from(document.getElementsByClassName('question-row'));

  questions.forEach(question => {
    question.addEventListener('click', (event) => {
      if (event.target.tagName === 'A') {
        return;
      }

      question.querySelector("[data-modal='questionDisplay']").click();
    });
  });
};

export default handleQuestionClick;

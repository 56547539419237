import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // Launch the Finicity UI using the token JSON from the site.
  connectToFinicity(event) {
    const csrfToken   = document.getElementsByName("csrf-token")[0].content
    const tokenUrl    = this.data.get("token-url");

    fetch(
      tokenUrl,
      {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
        }
      }
    ).then(
      response => response.json()
    ).then(
      json => this.launchFinicity(json)
    )

    event.preventDefault();
  }

  // Launch the Finicity UI using the token JSON from the site.
  launchFinicity(json) {
    const connectUrl = json.fix_connect_url;
    const callbackUrl = json.callback_url

    window.finicityConnect.launch(
      connectUrl,
      {
        success: (event) => { this.handleSuccess(event, callbackUrl); },
        cancel: (event) => { this.handleCancel(event); },
        error: (error) => { this.handleError(error); },
        loaded: () => { this.handleLoaded(); },
        route: (event) => { this.handleRoute(event); },
        user: (event) => { this.handleUser(event); }
      }
    );
  }

  // The user was able to connect to Finicity! Let the site know.
  handleSuccess(event, callbackUrl) {
    console.log("Success Fired!");
    const csrfToken   = document.getElementsByName("csrf-token")[0].content

    fetch(callbackUrl, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json",
        "Access-Control-Expose-Headers": "Location"
      },
      body: JSON.stringify({
        // TODO: What data is being sent to the callback?
      })
    }).then(
      //   response => window.location = response.headers.get("location")
      // ).then(
      response => response.json()
    ).then(
      json => {
        window.finicityConnect.destroy()
        window.location = json.customer_url
      }
    )
  }

  // The user cancelled the iframe
  handleCancel(event) {
    // TODO: Can we send this information back to the spark site so it can be logged?
    window.finicityConnect.destroy()
    console.log("The user cancelled the iframe", event);
  }

  // Some runtime error was generated during insideConnect
  handleError(error) {
    console.error("Some runtime error was generated during insideConnect ", error);
  }

  // This gets called only once after the iframe has finished loading
  handleLoaded() {
    console.log("This gets called only once after the iframe has finished loading ");
  }

  // This is called as the user navigates through Connect
  handleRoute(event) {
    console.log("This is called as the user navigates through Connect ", event);
  }

  // This is called as the user interacts with Connect
  handleUser(event) {
    console.log("This is called as the user interacts with Connect ", event);
  }
}

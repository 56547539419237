import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = true

import { Application } from "@hotwired/stimulus"
const application = Application.start()

// Add external Stimulus controllers here
import ContentLoader from "stimulus-content-loader"
application.register("content-loader", ContentLoader)

import ContextmenuController from "@kanety/stimulus-contextmenu"
application.register("contextmenu", ContextmenuController)

import NestedForm from "stimulus-rails-nested-form"
application.register("nested-form", NestedForm)

import Reveal from "stimulus-reveal-controller"
application.register("reveal", Reveal)

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export { application }

import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

/**
 * InlinePostController should be a very simple controller.
 * All it needs to do it act like a form, where forms cannot be used.
 * It needs to know about the form elements and where to post the data.
 * Ideally, the response will replace the turbo-frame and the
 * controller won't have to worry about anything after the post.
 */
export default class extends Controller {
  static targets = [ "input" ];
  static values = { url: String }

  submit () {
    new FetchRequest(
      "POST",
      this.urlValue,
      {
        headers: this.formHeaders(),
        body: this.formBody(),
        responseKind: "turbo-stream"
      }
    ).perform();
  }

  formHeaders () {
    return {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    };
  }

  formBody () {
    var data = new URLSearchParams();
    this.inputTargets.forEach(input => {
      data.append(input.name, input.value);
    });
    return data.toString();
  }
}

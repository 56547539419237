import TomSelectController from "./tom_select_controller"
import TomSelect from "tom-select"
import { FetchRequest } from "@rails/request.js"

export default class extends TomSelectController {
  static values = {
    settings: Object,
    url:      String
  }

  defaultSettings() {
    let overrideSettings = {
      // Override settings here...
      loadThrottle: null,
      openOnFocus: true,
      persist: false,
      preload: 'focus',
    };
    let settings = Object.assign(super.defaultSettings(), overrideSettings);
    return settings;
  }

  initialize() {
    this.loaded = false
  }

  connect() {
    let loadSettings = {
      load: (query, callback) => this.search(query, callback),
    };
    let settings = Object.assign(this.defaultSettings(), this.settingsValue, loadSettings);
    this.tomSelect = new TomSelect(this.element, settings);
  }

  async search(query, callback) {
    if (this.loaded) {
      callback()
      return
    }

    const querySeparator = this.urlValue.includes("?")? "&" : "?";
    const request = new FetchRequest(
      "GET",
      `${this.urlValue}${querySeparator}q=${query}`,
      { responseKind: "json" }
    )
    const response = await request.perform()

    // Identify unique groups and add to the select object
    if (response.ok) {
      const options = await response.json
      const optgroups = Array.from(
        new Set(options.map(option => option.optgroup))
      ).filter(n => n).map(
        optgroup => { return({ label: optgroup, value: optgroup }); }
      );

      if (this.tomSelect.settings["maxItems"] != null) {
        if (optgroups.length > 0) {
          options.unshift({ text: this.element.attributes.placeholder.value, value: "", optgroup: "" });
          optgroups.unshift({ label: "", value: "" });
        } else {
          options.unshift({ text: this.element.attributes.placeholder.value, value: "" });
        }
      }

      callback(options, optgroups)
      this.loaded = true
    } else {
      callback()
    }
  }
}

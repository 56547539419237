const closeModal = (): void => {
  document.addEventListener('click', (e) => {
    if (!(e.target instanceof HTMLAnchorElement)) {
      return;
    }

    if (e.target.dataset.closeModal === 'onClick') {
      const closeButton = document.querySelector('.modal.show .close');
      if (closeButton instanceof HTMLButtonElement) {
        closeButton.click();
      }
    }
  });
};

export default closeModal;

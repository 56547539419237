import { Controller } from "@hotwired/stimulus"

// I know this controller's name is a too specific,
// but I don't know what to call it.
// What term describes this show-only-one-at-a-time behavior?

export default class extends Controller {
  static targets = [ "tray" ]

  toggle (e) {
    e.preventDefault()

    const tray = e.params["id"]
    const el = this.trayTargets.find(el => el.dataset.tray === tray)

    if (el.classList.contains("d-none")) {
      this.show(e)
    } else {
      this.close(e)
    }
  }

  show (e) {
    e.preventDefault()

    const tray = e.params["id"]

    this.trayTargets.forEach(el => {
      if (el.dataset.tray === tray) {
        el.classList.remove("d-none");
      } else {
        el.classList.add("d-none");
      }
    })

    this.element.addEventListener("keydown", (e) => {
      const ESCAPE_KEY = 27;

      if (e.keyCode === ESCAPE_KEY) {
        this.close(e)
      }
    })
  }

  close (e) {
    e.preventDefault()

    this.trayTargets.forEach(el => {
      el.classList.add("d-none");
    })
  }
}

/**
<div data-controller="tray">
  <h1>Toggle Trays</h1>

  <a href="#" data-action="tray#show" data-tray-id-param="foo">Show Foo</a>
  <a href="#" data-action="tray#show" data-tray-id-param="bar">Show Bar</a>
  <a href="#" data-action="tray#show" data-tray-id-param="baz">Show Baz</a>

  <p class="d-none" data-tray-target="foo">Foo!</p>
  <p class="d-none" data-tray-target="bar">Bar!</p>
  <p class="d-none" data-tray-target="baz">Baz!</p>
</div>
**/

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filterText", "filterable"]

  setFilter(event) {
    let matchText = event.target.value.toLowerCase()

    this.filterableTargets.forEach((e) => {
      let key = e.getAttribute("data-filter-key")
      e.classList.toggle("d-none", !key.includes(matchText))
    })
  }
}

import { Controller } from '@hotwired/stimulus';
import Tribute from 'tributejs';

export default class extends Controller {
  static targets = ['field']

  connect() {
    this.editor = this.fieldTarget.editor;
    this.url = this.data.get('url');
    this.initializeTribute();
  }

  disconnect() {
    if (!this.tribute) return;
    this.tribute.detach(this.fieldTarget);
  }

  initializeTribute() {
    // Prevent Tribute initialization during Turbolinks preview
    if (document.documentElement.hasAttribute('data-turbo-preview')) return;

    const tributeOptions = {
      allowSpaces: true,
      lookup: 'name',
      values: this.fetchUsers.bind(this),
    };

    if (this.data.has('container')) {
      const selector = this.data.get('container');
      tributeOptions.menuContainer = this.fieldTarget.closest(selector);
      tributeOptions.positionMenu = false;
    }

    this.tribute = new Tribute(tributeOptions);
    this.tribute.attach(this.fieldTarget);
    this.tribute.range.pasteHtml = this.pasteHtml.bind(this);
    this.fieldTarget.addEventListener('tribute-replaced', this.replaced);
  }

  fetchUsers(text, callback) {
    fetch(`${this.url}.json?query=${text}`)
      .then((response) => response.json())
      .then((users) => callback(users))
      .catch(() => callback([]));
  }

  replaced(e) {
    const mention = e.detail.item.original;
    const attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(' ');
  }

  pasteHtml(html, startPos, endPos) {
    this.editor.setSelectedRange([startPos, endPos]);
    this.editor.deleteInDirection('backward');
  }
}

import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    settings: Object
  }
  static targets = ["selectControl"]

  defaultSettings() {
    return {
      allowEmptyOption: true,
      maxItems: 1,
      maxOptions: null,
      plugins: ["clear_button", "dropdown_input"],
      selectOnTab: true,
      render: {
        option: function (data, escape) {
          if (data.value == "") {
            return '<div></div>';
          }
          return '<div>' + escape(data.text) + '</div>';
        },
        item: function (data, escape) {
          if (data.value == "") {
            return '<div style="color: rgb(146, 149, 152);">' + escape(data.text) + '</div>';
          }
          return '<div>' + escape(data.text) + '</div>';
        },
      },
    }
  }

  connect() {
    let settings = Object.assign(this.defaultSettings(), this.settingsValue);
    this.tomSelect = new TomSelect(this.selectControlTarget, settings);
  }

  selectAll() {
    this.tomSelect.addItems(Object.keys(this.tomSelect.options));
  }

  disconnect() {
    this.tomSelect.destroy()
    this.tomSelect = null
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container', 'alert', 'message']

  static typeClasses = {
    notice: 'alert-info',
    alert: 'alert-warning',
    error: 'alert-danger',
  }

  connect() {
    this.update();
  }

  handle(event) {
    const xhr = event.detail[0];
    const message = xhr.getResponseHeader('X-Message');
    const type = xhr.getResponseHeader('X-Message-Type');

    this.data.set('message', message);
    this.data.set('type', type);

    this.update();
  }

  show() {
    this.containerTarget.classList.remove('d-none');
  }

  hide() {
    this.containerTarget.classList.add('d-none');
  }

  update() {
    const message = this.data.get('message');
    const type = this.data.get('type');

    if (!message || message === 'null') return;

    this.messageTarget.innerHTML = message;

    this.alertTarget.classList.remove('alert-info', 'alert-warning', 'alert-danger');

    const typeClass = this.constructor.typeClasses[type];
    this.alertTarget.classList.add(typeClass);

    this.show();
  }
}

import { Controller } from '@hotwired/stimulus';

let recurlyElements
let card
let includePaymentForm = false

export default class extends Controller {
  static targets = [
    "businessLegalName",
    "businessTaxId",
    "businessContactName",
    "businessContactPhone",
    "editPaymentButtonShow",
    "editPaymentButtonHide",
    "paymentFormTemplate",
    "creditCardInput",
    "businessForm",
    "editPaymentFormContainer"
  ]

  connect() {
    recurly.configure(this.businessFormTarget.dataset.recurlyKey)

    if (this.businessFormTarget.dataset.newOnboardingBusiness === "true") {
      this.show_payment_fields(null)
    }
  }

  submitForm(event) {
    let isValid = this.validateForm()

    // If our form is invalid, prevent default on the event
    // so that the form is not submitted
    if (!isValid) {
      return event.preventDefault()
    }

    if (includePaymentForm) {
      this.submit_payment_info(event, this.businessFormTarget)
    }
  }

  validateForm() {
    let isValid = true
    let paymentFormContainer = document.getElementById('payment-form-container')
    if (paymentFormContainer) {
      // Scope to only target the payment form
      let errorMessages = paymentFormContainer.querySelectorAll('.invalid-feedback')

      // Clear old error messages
      errorMessages.forEach((errorMessage) => {
        errorMessage.remove()
      }) 
    }

    const requiredFields = [
      this.businessLegalNameTarget
    ]

    requiredFields.forEach((requiredField) => {
      const requiredFieldValue = requiredField.value

      if (requiredFieldValue === null || requiredFieldValue.trim() === "") {
        isValid = false
  
        // Add error class to input element
        requiredField.classList.add('is-invalid')
  
        // Append error message to the end of the input element's container
        var errorElement = document.createElement("div")
        errorElement.classList.add('invalid-feedback')
        errorElement.innerHTML = "This field is required"
        requiredField.parentElement.append(errorElement)
        requiredField.focus()
      } else {
        // Remove the input's invalid classes, if any
        requiredField.classList.remove('is-invalid')
      }

    })

    return isValid
  }

  show_payment_fields(event) {
    if (event) {
      event.preventDefault()
    }

    // Do submit/validate payment information
    includePaymentForm = true

    // Insert the payment form markup from the template
    this.editPaymentFormContainerTarget.insertAdjacentHTML('beforebegin', this.paymentFormTemplateTarget.innerHTML)

    if (this.creditCardInputTarget) {
      recurlyElements = recurly.Elements()
      card = recurlyElements.CardElement()

      // Display Recurly's credit card element on the appropriate DOM element
      card.attach('#credit-card')
    }

    // Swap the show/hide buttons
    if (this.businessFormTarget.dataset.newOnboardingBusiness === "false") {
      this.editPaymentButtonShowTarget.classList.add('d-none')
      this.editPaymentButtonHideTarget.classList.remove('d-none')
    }
  }

  hide_payment_fields(event) {
    event.preventDefault()

    // Do not submit/validate payment information
    includePaymentForm = false

    // Be tidy and remove the credit card element prior to removing
    // the form markup (avoids memory leaks)
    card.remove('#credit-card')

    // Remove the form markup
    document.getElementById('payment-form-container').remove()

    // Swap the show/hide buttons
    this.editPaymentButtonShowTarget.classList.remove('d-none')
    this.editPaymentButtonHideTarget.classList.add('d-none')
  }

  // This isn't great. Recurly recommends taking their generic (Rails?) error messages
  // and rewriting them to something more useful. Hope they never change their errors.
  getErrorMessage(errorKey, errorField) {
    const forField = errorField ? ` ${errorField.replace('_', ' ')}` : ''

    switch (errorKey) {
      case `is invalid`:
        return `Please enter a ${forField}`;
      case `can't be blank`:
        return `Please enter a ${forField}`;
      case `can't be empty`:
        if (errorField === 'address1') return 'Please enter the first line of your address'
        return `Please enter a ${forField}`;
      default:
        return 'Invalid'
    }
  }

  addErrorMessages(err) {
    const cardErrors = [];

    if (err.details) {
      err.details.map(error => {
        const inputElement = document.getElementById(`recurly_${error.field}`)
        if (inputElement) {
          // Add error class to input element
          inputElement.classList.add('is-invalid')
  
          // Append error message to the end of the input element's container
          const errorElement = document.createElement("div")
          errorElement.classList.add('invalid-feedback');
          errorElement.append(error.messages.map(errorKey => this.getErrorMessage(errorKey, error.field)).join('/'))
          inputElement.parentElement.append(errorElement)
          inputElement.focus()
        }
  
        // The CardElement is different. We'll consolidate errors for these fields
        // and display them as a single error on the cardElement field.
        if (['number', 'month', 'year'].includes(error.field)) {
          const errorMessage = error.messages.map(errorKey => this.getErrorMessage(errorKey, error.field)).join('/')
          cardErrors.push(errorMessage)
        }
      })
    } else if (err.message) {
      cardErrors.push(err.message)
    }

    if (cardErrors.length) {
      const cardElement = document.getElementById('credit-card')
      var cardFirstName = document.getElementById('recurly_first_name')

      // Add error class to parent element
      cardElement.classList.add('is-invalid')

      const errorContainerElement = document.createElement("ul")
      errorContainerElement.classList.add('invalid-feedback');
      cardErrors.map(error => {
        const errorElement = document.createElement("li")
        errorElement.append(error)
        errorContainerElement.append(errorElement)
      })
      cardElement.parentElement.append(errorContainerElement)
      cardFirstName.focus()
    }
  }

  removeErrorMessages() {
    const recurlyRequiredFields = [
      'address1',    // Server validation
      'city',        // Server validation
      'country',     // Server validation
      'first_name',  // JS validation
      'last_name',   // js validation
      'month',       // JS validation
      'number',      // JS validation
      'postal_code', // Server validation
      'state',       // Server validation
      'year',        // JS validation
    ]

    recurlyRequiredFields.map(field => {
      const inputElement = document.getElementById(`recurly_${field}`)
      if (inputElement) {
        // Remove the input's invalid classes, if any
        inputElement.classList.remove('is-invalid')
        // Remove the error messages, if any
        const errorMessage = inputElement.parentElement.querySelector('.invalid-feedback')
        if (errorMessage) errorMessage.remove()
      }
    })

    // number, month, year
    const cardElement = document.getElementById('credit-card')

    // Remove the input's invalid classes, if any
    cardElement.classList.remove('is-invalid')

    // Remove the error messages, if any
    const errorMessage = cardElement.parentElement.querySelector('.invalid-feedback')
    if (errorMessage) errorMessage.remove()
  }

  enableSubmitButton() {
    let paymentForm = this.businessFormTarget
    const submitButton = paymentForm.querySelector('#payment-details-submit-button')
    if (submitButton) submitButton.removeAttribute('data-disable-with')
  }

  submit_payment_info(event, paymentForm) {
    event.preventDefault()
    const that = this

    if (!paymentForm) return

    recurly.token(recurlyElements, paymentForm, function(error, _token) {
      if (error) {
        that.removeErrorMessages()
        that.addErrorMessages(error)
        that.enableSubmitButton()
      } else {
        paymentForm.submit()
      }
    })
  }
}
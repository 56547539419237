import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connectToPlaid(event) {
    const callbackUrl = this.data.get("callback-url");
    const tokenUrl = this.data.get("token-url");
    const csrfToken = document.querySelector(`[name='csrf-token']`).content;

    fetch(tokenUrl, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        Plaid.create({
          token: data.link_token,
          onSuccess(publicToken, metadata) {
            const formData = new FormData();
            formData.append('public_token', publicToken);
            fetch(callbackUrl, {
              method: 'POST',
              headers: {
                'X-CSRF-Token': csrfToken,
              },
              body: formData,
            })
              .then((response) => {
                window.location = response.headers.get('Location');
              });
          },
        }).open();
      });
    event.preventDefault();
  }
}

import PrefetchSelectController from "./prefetch_select_controller"
import { FetchRequest } from "@rails/request.js"

export default class extends PrefetchSelectController {
  static values = {
    settings: Object,
    url: String,
    createUrl: String,
    createKey: String
  }

  defaultSettings() {
    let overrideSettings = {
      // Override settings here...
      loadThrottle: null,
      openOnFocus: true,
      persist: true,
      preload: 'focus',
      create: (input, callback) => this.create(input, callback),
      createFilter: function (input) {
        input = input.toLowerCase();
        return !(Object.values(this.options).some(option => option.text.toLowerCase() === input));
      }
    };
    let settings = Object.assign(super.defaultSettings(), overrideSettings);
    return settings;
  }

  async create(input, callback) {
    const createUrl = this.createUrlValue;
    const createKey = this.createKeyValue;
    const body = JSON.stringify({ [createKey]: { name: input } });
    const request = new FetchRequest(
      "POST",
      createUrl,
      {
        body,
        responseKind: "json",
      }
    );
    const response = await request.perform();
    if (response.ok) {
      const body = await response.json;
      callback({ value: body["id"], text: body["name"] });
    } else {
      callback()
    }
  }
}

import PrefetchSelectController from "../prefetch_select_controller"
import { FetchRequest } from "@rails/request.js"

export default class extends PrefetchSelectController {
  async search(query, callback) {
    if (this.loaded) {
      callback()
      return
    }

    const querySeparator = this.urlValue.includes("?")? "&" : "?";
    const request = new FetchRequest(
      "GET",
      `${this.urlValue}${querySeparator}q=${query}`,
      { responseKind: "json" }
    )
    const response = await request.perform()

    if (response.ok) {
      const options = await response.json
      const optgroups = Array.from(
        new Set(options.map(option => option.optgroup))
      ).filter(n => n).map(
        optgroup => { return({ label: optgroup, value: optgroup }); }
      );

      if (this.tomSelect.settings["maxItems"] != null) {
        if (optgroups.length > 0) {
          options.unshift({ text: this.element.attributes.placeholder.value, value: "", optgroup: "" });
          options.unshift({ text: "New Transfer", value: "__new__", optgroup: "" });
          optgroups.unshift({ label: "", value: "" });
        } else {
          options.unshift({ text: this.element.attributes.placeholder.value, value: "" });
          options.unshift({ text: "New Transfer", value: "__new__" });
        }
      }

      callback(options, optgroups)
      this.loaded = true
    } else {
      callback()
    }
  }
}

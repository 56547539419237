import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "widget", "controls", "startButton", "currentFolder", "folderName", "addButton", "cancelButton"
  ]

  initialize() {
    this.widgetTarget.classList.remove("d-none")
  }

  startSelection() {
    this.startButtonTarget.classList.add("d-none")
    this.controlsTarget.classList.remove("d-none")
    this.controlsTarget.classList.add("d-flex")
    this.folderNameTarget.focus()
  }

  cancelNewFolder() {
    this.startButtonTarget.classList.remove("d-none")
    this.controlsTarget.classList.remove("d-flex")
    this.controlsTarget.classList.add("d-none")
    this.folderNameTarget.value = ""
  }
}

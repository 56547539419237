import Tab from 'bootstrap/js/dist/tab'

// TODO: Rewrite this like the tooltips

function initTabs() {
  var triggerTabList = [].slice.call(document.querySelectorAll('#myTab button'))

  triggerTabList.forEach(function (triggerEl) {
    var tabTrigger = new Tab(triggerEl)
  
    triggerEl.addEventListener('click', function (event) {
      event.preventDefault()
      tabTrigger.show()
    })
  })
}

export default initTabs;

import { Controller } from '@hotwired/stimulus'
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["stateSelect", "city", "street", "street2", "postalCode"]
  
  async change(event) {
    let country = event.target.selectedOptions[0].value

    const response = await get(`/js-api/states?country=${country}`)

    if (response.ok) {
      const states = await response.json

      let arrOptions = []
      arrOptions.push("<option value>Select</option>")
      Object.entries(states).forEach(([key, value]) => {
        arrOptions.push(`<option value='${value}'>${key}</option>`)
      })
      this.stateSelectTarget.innerHTML = arrOptions.join()
      if (country === "US") {
        this.stateSelectTarget.nextElementSibling.innerHTML = "State"
        this.cityTarget.nextElementSibling.innerHTML = "City"
        this.streetTarget.nextElementSibling.innerHTML = "Street address or P.O. Box"
        this.street2Target.nextElementSibling.innerHTML = "Apt, suite, unit, building, floor, etc."
        this.postalCodeTarget.nextElementSibling.innerHTML = "Zip Code"
      } else {
        this.stateSelectTarget.nextElementSibling.innerHTML = "State / Province / Region"
        this.cityTarget.nextElementSibling.innerHTML = "City / Town"
        this.streetTarget.nextElementSibling.innerHTML = "Address Line 1"
        this.street2Target.nextElementSibling.innerHTML = "Address Line 2 (optional)"
        this.postalCodeTarget.nextElementSibling.innerHTML = "Postal Code"
      }
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import bankAccountSyncChannel from "../../../channels/bank/account/sync_channel";

export default class extends Controller {
  connect() {
    if (!["failed", "processed"].includes(this.data.get("status"))) {
      this.initChannel();
    }
  }

  initChannel() {
    const identifier = this.identifier;
    const id = this.data.get("id");
    const refreshUrl = this.data.get("refresh-url");
    const parentElement = this.element.parentElement;
    var active = true;

    const syncChannel = bankAccountSyncChannel({
      id: id,
      received: (data) => {
        if (active) {
          fetch(refreshUrl)
            .then((response) => {
              response.text().then((text) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(text, "text/html");
                const content = doc.documentElement.querySelectorAll(`[data-${identifier}-id='${id}']`).item(0);
                parentElement.innerHTML = content.outerHTML;
              });
            });

          if (["failed", "processed"].includes(data.status)) {
            active = false;
            syncChannel.unsubscribe();
          }
        }
      },
    });
  }
}

import { Controller } from "@hotwired/stimulus"

/**
 * TransactionsController is not a typical Stimulus controller.
 * It is highly specific to the Spark transactions page.
 * Ideally, this would be split across several generic controllers,
 * but we don't know how to do that yet, so we are leaning into the
 * big old ball of mud controller for now.
 *
 * This controller is responsible for tracking all transaction rows,
 * knowing which row is current at any time, and knowing whether the
 * current row has opened the inline adjuster or the tray adjuster.
 *
 * This controller also tracks keystrokes, and will move the current
 * row in response to them. It also opens the inline or tray adjuster
 * ui based on those keystrokes.
 *
 * In the future this controller may also track which rows are also selected,
 * as it would be helpful to close an open adjuster ui when the row is selected.
 * Or, when any row is selected! Do we want an open adjuster while rows are selected?
 */
export default class extends Controller {
  static targets = ["row", "tray"]

  initialize() {
    // The transaction id of the current row/tray
    this.current = null;

    // References to bound event handlers to enable removal
    this.boundKeypressEventHandler = null;
    this.boundInlineClickEventHandler = null;
  }

  connect() {
    this.boundInlineClickEventHandler = this.inlineClickEventHandler.bind(this);
    this.boundKeypressEventHandler = this.keypressEventHandler.bind(this);

    document.addEventListener("keydown", this.boundKeypressEventHandler);
    document.addEventListener("click", this.boundInlineClickEventHandler);

    this.setCurrent(this.rowTargets[0].dataset["transaction"]);
  }

  disconnect() {
    document.removeEventListener("keydown", this.boundKeypressEventHandler);
    document.removeEventListener("click", this.boundInlineClickEventHandler);

    this.boundKeypressEventHandler = null;
    this.boundInlineClickEventHandler = null;
  }

  setCurrent(tx) {
    if (this.current != tx) {
      if (this.currentRow() != null) {
        this.closeInline();
        this.currentRow().classList.remove("current")
      }
      if (this.currentTray() != null) {
        this.closeTray();
      }
    }

    this.current = tx;
    if (this.currentRow() != null) {
      this.currentRow().classList.add("current")
      // We know this is the location of the checkbox... so dirty...
      this.currentRow().firstElementChild.firstElementChild.focus({ focusVisible: true });
    }
  }

  currentRow() {
    if (this.current == null) {
      return;
    }

    return this.rowTargets.find(el => el.dataset["transaction"] === this.current);
  }

  openInline() {
    if (this.currentRow() == null) {
      return;
    }

    for (let element of this.currentRow().children) {
      if (element.dataset["adjuster"] == "display") {
        element.classList.add("d-none");
      } else if (element.dataset["adjuster"] == "inline") {
        element.classList.remove("d-none");
      }
    }
  }

  closeInline() {
    if (this.currentRow() == null) {
      return;
    }

    for (let element of this.currentRow().children) {
      if (element.dataset["adjuster"] == "display") {
        element.classList.remove("d-none");
      } else if (element.dataset["adjuster"] == "inline") {
        element.classList.add("d-none");
      }
    }
  }

  inlineOpened() {
    if (this.currentRow() == null) {
      return;
    }

    for (let element of this.currentRow().children) {
      if (element.dataset["adjuster"] == "display") {
        return element.classList.contains("d-none");
      }
    }

    return false;
  }

  currentTray() {
    if (this.current == null) {
      return;
    }

    return this.trayTargets.find(el => el.dataset["transaction"] === this.current);
  }

  toggleTray(e) {
    e.preventDefault()

    const transaction = e.target.dataset["transaction"];
    this.setCurrent(transaction);
    // Toggling tray, inline must always be closed
    this.closeInline();

    if (this.trayOpened()) {
      this.closeTray();
    } else {
      this.openTray();
    }
  }

  openTray() {
    if (this.currentTray() == null) {
      return;
    }

    this.currentTray().classList.remove("d-none");
    let firstInput = this.currentTray().querySelector(":scope input:not([type=hidden])");
    if (firstInput != null) {
      firstInput.focus({ focusVisible: true });
    }
  }

  closeTray() {
    if (this.currentTray() == null) {
      return;
    }

    this.currentTray().classList.add("d-none");
  }

  trayOpened() {
    if (this.currentTray() == null) {
      return false;
    }

    if (this.currentTray() == null) {
      return false
    }

    return !this.currentTray().classList.contains("d-none");
  }

  toggleRowCheck() {
    if (this.currentRow() == null) {
      return;
    }

    const checkbox = this.currentRow().querySelector(":scope input[type=checkbox]");
    if (checkbox == null) {
      return;
    }

    checkbox.checked = !checkbox.checked;
  }

  keypressEventHandler(e) {
    const ENTER_KEY = 13;
    const ESCAPE_KEY = 27;
    const UP_KEY = 38;
    const DOWN_KEY = 40;
    const I_KEY = 73;
    const J_KEY = 74;
    const K_KEY = 75;
    const T_KEY = 84;
    const X_KEY = 88;

    if (e.keyCode === ESCAPE_KEY) {
      if (document.activeElement.matches("body")) {
        this.closeInline();
        this.closeTray();
      } else {
        e.preventDefault();
        document.activeElement.blur();
        return;
      }
    }

    if ((e.target.matches("input") && !e.target.matches("input[type=checkbox]")) ||
      e.target.matches("select") ||
      e.target.matches("button") ||
      e.target.matches("trix-editor")) {
      return;
    }

    if (e.keyCode === DOWN_KEY || e.keyCode === J_KEY) {
      e.preventDefault();
      // down
      const inline = this.inlineOpened();
      const tray = this.trayOpened();

      var index = this.rowTargets.indexOf(this.currentRow());
      index = Math.min((this.rowTargets.length - 1), (index + 1))
      this.setCurrent(this.rowTargets[index].dataset["transaction"]);

      if (inline == true) {
        this.openInline()
      } else if (tray == true) {
        this.openTray()
      }
    } else if (e.keyCode === UP_KEY || e.keyCode === K_KEY) {
      e.preventDefault();
      // up
      const inline = this.inlineOpened();
      const tray = this.trayOpened();

      var index = this.rowTargets.indexOf(this.currentRow());
      index = Math.max(0, (index - 1));
      this.setCurrent(this.rowTargets[index].dataset["transaction"]);

      if (inline == true) {
        this.openInline()
      } else if (tray == true) {
        this.openTray()
      }
    } else if (e.keyCode === I_KEY) {
      e.preventDefault();
      // inline adjust
      this.closeTray()
      if (this.inlineOpened()) {
        this.closeInline();
      } else {
        this.openInline();
      }
    } else if (e.keyCode === T_KEY) {
      e.preventDefault();
      // tray adjust
      this.closeInline()
      if (this.trayOpened()) {
        this.closeTray();
      } else {
        this.openTray();
      }
    } else if (e.keyCode === ENTER_KEY && !this.trayOpened() && !this.inlineOpened()) {
      e.preventDefault();
      // tray adjust
      this.openTray();
    } else if (e.keyCode === X_KEY && !this.trayOpened() && !this.inlineOpened()) {
      e.preventDefault();
      // tray adjust
      this.toggleRowCheck();
    }
  }

  inlineClickEventHandler(e) {
    const listeningElement = e.target.closest("[data-listen=click]")
    if (listeningElement == null) {
      return;
    }

    const row = e.target.closest("[data-transaction]")
    if (row == null) {
      return;
    }

    e.preventDefault();
    this.setCurrent(row.dataset["transaction"]);
    this.openInline();
  }
}

import { Controller } from "@hotwired/stimulus"
import Modal from "bootstrap/js/dist/modal";

export default class extends Controller {
  initialize() {
    this.modal = null;
  }

  connect(){
    this.modal = new Modal(this.element);
    this.element.addEventListener('hidden.bs.modal', this.cleanup.bind(this))
    this.modal.show();
  }

  disconnect() {
    this.cleanup();
    this.modal = null;
  }

  cleanup() {
    if (this.modal != null) {
      this.modal.hide();
    }
  }
}

import { Controller } from '@hotwired/stimulus';
import { isEmpty } from 'lodash';
import { addLoaderToButton, BootstrapSpinnerHTML } from '../../../lib/utils';

export class BaseManageController extends Controller {
  leftMultiSelectTarget!: HTMLSelectElement;

  rightMultiSelectTarget!: HTMLSelectElement;

  submitButtonTarget!: HTMLButtonElement;
}

export default class ManageController extends (Controller as typeof BaseManageController) {
  static targets = ['leftMultiSelect', 'rightMultiSelect', 'submitButton'];

  connect = () => {
    this.submitButtonTarget.disabled = true;
  }

  handleSubmit = () => {
    addLoaderToButton(this.submitButtonTarget, BootstrapSpinnerHTML(this.#submitButtonProcessingText));
  }

  moveToRightColumn = () => {
    const leftSelectedOptions = this.leftMultiSelectTarget.selectedOptions;

    this.#moveItems({
      items: leftSelectedOptions,
      from: this.leftMultiSelectTarget,
      to: this.rightMultiSelectTarget,
      action: 'add',
    });
  }

  moveToLeftColumn = () => {
    const rightSelectedOptions = this.rightMultiSelectTarget.selectedOptions;

    this.#moveItems({
      items: rightSelectedOptions,
      from: this.rightMultiSelectTarget,
      to: this.leftMultiSelectTarget,
      action: 'remove',
    });
  }

  #moveItems = ({
    items, from, to, action,
  }: {
    items: HTMLCollectionOf<HTMLOptionElement>,
    from: HTMLSelectElement,
    to: HTMLSelectElement,
    action: 'add' | 'remove',
  }) => {
    if (!isEmpty(items)) { this.#enableSubmitButton(); }

    const selectedIds = Array.from(items).map((option) => option.value);

    Array.from(items).forEach((option) => {
      /* eslint-disable no-param-reassign */
      option.selected = false;
      from.removeChild(option);
      to.appendChild(option);
    });

    switch (action) {
      case 'add':
        selectedIds.forEach(this.#addHiddenField);
        break;
      case 'remove':
        selectedIds.forEach(this.#removeHiddenField);
        break;
      default:
        break;
    }
  }

  #enableSubmitButton = () => {
    this.submitButtonTarget.disabled = false;
  }

  #addHiddenField = (value: string) => {
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.multiple = true;
    hiddenField.value = value;
    hiddenField.name = `${this.#hiddenFieldKey}[]`;
    hiddenField.id = `${this.#hiddenFieldKey}`;
    this.element.appendChild(hiddenField);
  }

  #removeHiddenField = (value: string) => {
    const hiddenField = this.element.querySelector(`input[type=hidden][value="${value}"]`);

    if (hiddenField && hiddenField.parentElement) { hiddenField.parentElement.removeChild(hiddenField); }
  }

  get #submitButtonProcessingText(): string {
    return this.submitButtonTarget.dataset.processingText ?? 'Loading...';
  }

  get #hiddenFieldKey(): string {
    const key = (this.element as HTMLFormElement).dataset.hiddenFieldKey;

    if (isEmpty(key)) {
      throw new Error('The `data-hidden-key-field` is required.');
    }

    return key!;
  }
}

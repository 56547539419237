/* eslint-disable no-param-reassign */
import {
  find,
} from 'lodash';
import {
  Controller,
} from '@hotwired/stimulus';

class AccountTypeFilter {
  static activeClass = 'bg-white'

  constructor(elements, emptyElement) {
    this.elements = elements;
    this.emptyElement = emptyElement;
    this.accountTypes = elements.reduce((accountTypes, element) => {
      accountTypes[element.dataset.accountType] = true;
      return accountTypes;
    }, {});
  }

  toggle(element) {
    const {
      accountType,
    } = element.dataset;

    if (this.isActive(element)) {
      this.deactivate(element);
      this.hide(accountType);
    } else {
      this.activate(element);
      this.show(accountType);
    }

    if (this.hasActiveAccountTypes()) {
      this.hideNoResults();
    } else {
      this.showNoResults();
    }
  }

  isActive(element) {
    return element.classList.contains(this.constructor.activeClass);
  }

  activate(element) {
    element.classList.add(this.constructor.activeClass);
  }

  deactivate(element) {
    element.classList.remove(this.constructor.activeClass);
  }

  show(accountType) {
    this.accountTypes[accountType] = true;
    this.findElement(accountType).forEach((element) => element.classList.remove('d-none'));
  }

  hide(accountType) {
    this.accountTypes[accountType] = false;
    this.findElement(accountType).forEach((element) => element.classList.add('d-none'));
  }

  findElement(accountType) {
    return this.elements.filter((element) => element.dataset.accountType === accountType);
  }

  activeAccountTypes() {
    return Object.keys(this.accountTypes).filter((accountType) => this.accountTypes[accountType]);
  }

  hasActiveAccountTypes() {
    return this.activeAccountTypes().length > 0;
  }

  showNoResults() {
    this.emptyElement.classList.remove('d-none');
  }

  hideNoResults() {
    this.emptyElement.classList.add('d-none');
  }
}

class AccountNameFilter {
  constructor(elements) {
    this.elements = elements;
  }

  search(value, showInactive) {
    let filteredElements = [];

    if (value) {
      const pattern = new RegExp(value, 'i');

      this.elements.forEach((element) => {
        if (pattern.test(element.dataset.name)) {
          filteredElements.push(element);
          this.show(element);
        } else {
          this.hide(element);
        }
      });
    } else {
      filteredElements = this.elements;
      this.elements.forEach(this.show);
    }

    // respect current account status filter
    const accountStatusService = new AccountStatusFilter(filteredElements);

    if (showInactive) {
      accountStatusService.showInactiveAccounts();
    } else {
      accountStatusService.hideInactiveAccounts();
    }
  }

  show(element) {
    element.classList.remove('d-none');
    element.dataset.matchesSearch = 'true';
  }

  hide(element) {
    element.classList.add('d-none');
    element.dataset.matchesSearch = 'false';
  }
}

class AccountStatusFilter {
  static activeClass = 'bg-white';

  constructor(elements) {
    this.elements = elements;
    this.hideInactiveAccounts();
  }

  filterIsActive(element) {
    return element.classList.contains(this.constructor.activeClass);
  }

  activateFilterButton(element) {
    element.classList.add(this.constructor.activeClass);
  }

  deactivateFilterButton(element) {
    element.classList.remove(this.constructor.activeClass);
  }

  toggle(filterElem) {
    if (this.filterIsActive(filterElem)) {
      this.deactivateFilterButton(filterElem);
      this.hideInactiveAccounts();
    } else {
      this.activateFilterButton(filterElem);
      this.showInactiveAccounts();
    }
  }

  showInactiveAccounts() {
    this.findAccountElements(false).forEach((element) => element.classList.remove('d-none'));
  }

  hideInactiveAccounts() {
    this.findAccountElements(false).forEach((element) => element.classList.add('d-none'));
  }

  findAccountElements(activeStatus) {
    const stringStatus = activeStatus ? 'true' : 'false';
    return this.elements.filter((element) => {
      const {
        accountStatusActive,
        matchesSearch,
      } = element.dataset;
      return accountStatusActive === stringStatus && (matchesSearch || 'false') === 'true';
    });
  }
}

export default class extends Controller {
  static targets = ['accountList', 'accountType', 'accountName', 'empty', 'accountTypeFilter']

  connect() {
    this.typeFilters = new AccountTypeFilter(this.accountTypeTargets, this.emptyTarget);
    this.nameFilters = new AccountNameFilter(this.accountNameTargets);
    this.activeStatusFilter = new AccountStatusFilter(this.accountNameTargets);
  }

  filterTypes(event) {
    this.typeFilters.toggle(event.target);
  }

  filterNames(event) {
    const archivedFilterElem = find(this.accountTypeFilterTargets, (elem) => elem.dataset.accountType === 'archived');
    const showInactive = this.activeStatusFilter.filterIsActive(archivedFilterElem);
    this.nameFilters.search(event.target.value, showInactive);
  }

  filterStatus(event) {
    this.activeStatusFilter.toggle(event.target);
  }
}

import { isEmpty, isNaN } from 'lodash';

export const isInvalidNumber = (number: number | string | null | undefined): boolean => isEmpty(number) || isNaN(number);
export const isValidNumber = (number: number | string | null | undefined): boolean => !isInvalidNumber(number);

/**
 * Return the normalized, "safe-to-cast-to-number" version of a string.
 * Removes all characters except for digits and dots.
 * Removes all dots except for the first occurence (fix NaN error)
 * @param string
 */
/* eslint-disable no-useless-escape, no-plusplus, no-param-reassign */
export const toValidDigitsString = (string: string): string => string.replace(/[^\d\.]/g, '')
  .replace(/\./g, ((idx) => (m: string) => (!idx++ ? m : ''))(0));

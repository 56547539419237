// Alerts for dismissing
import 'bootstrap/js/dist/alert';

// Buttons for toggling states and checkbox/radio functionality
import 'bootstrap/js/dist/button';

// Carousel for all slide behaviors, controls, and indicators
// import 'bootstrap/js/dist/carousel'

// Collapse for toggling visibility of content (required for responsive navigation)
import 'bootstrap/js/dist/collapse';

// import 'bootstrap/js/dist/offcanvas'
import 'bootstrap/js/dist/popover';

// Scrollspy for scroll behavior and navigation updates
import 'bootstrap/js/dist/scrollspy';

import 'bootstrap/js/dist/dropdown';

import 'bootstrap/js/dist/tab'
// import 'bootstrap/js/dist/toast'

// Navbar for extending our Collapse plugin to implement responsive behavior
// Tooltips and popovers for displaying and positioning (also requires Popper.js)

import initTooltips from './init_tooltips';
import initTabs from './init_tabs';

document.addEventListener('DOMContentLoaded', () => {
  initTooltips();
  initTabs();
});

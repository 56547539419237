import CheckboxSelectAll from "stimulus-checkbox-select-all"
import { FetchRequest } from "@rails/request.js"

export default class extends CheckboxSelectAll {
  static targets = ["bulkActionUrls", "bulkAccountSelect", "bulkProjectSelect", "bulkTargetSelect"]

  adjust(event) {
    event.preventDefault()

    let data = new FormData()

    this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value))

    if (this.bulkAccountSelectTarget.value.length > 0) {
      data.append("account_id", this.bulkAccountSelectTarget.value)
    }

    if (this.bulkProjectSelectTarget.value.length > 0) {
      data.append("project_id", this.bulkProjectSelectTarget.value)
    }

    if (this.bulkTargetSelectTarget.value.length > 0) {
      data.append("target_id", this.bulkTargetSelectTarget.value)
    }

    new FetchRequest(
      "POST",
      this.bulkActionUrlsTarget.dataset.adjustTransactionsUrl,
      {
        body: data,
        responseKind: "turbo-stream"
      }
    ).perform();
  }

  remove(event) {
    event.preventDefault()

    let deleteReason = prompt("Enter a reason for deleting these transactions or cancel to abort")
    if (deleteReason) {
      let data = new FormData()
      this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value))
      data.append("reason", deleteReason);

      new FetchRequest(
        "POST",
        this.bulkActionUrlsTarget.dataset.removeTransactionsUrl,
        {
          body: data,
          responseKind: "turbo-stream"
        }
      ).perform();
    }
  }

  formHeaders() {
    return {
      "Accept": "text/vnd.turbo-stream.html, text/html, application/xhtml+xml",
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    };
  }
}

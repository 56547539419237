import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "button", "submit", "progress", "progressBar"]

  connect() {
    this.inputTarget.addEventListener("change", () => this._submitForm())

    // @see https://edgeguides.rubyonrails.org/active_storage_overview.html#example
    addEventListener("direct-upload:initialize", () => this.fileCount++)
    addEventListener("direct-upload:start", () => this.uploadingCount++)
    addEventListener("direct-upload:end", () => this.uploadedCount++)
    addEventListener("direct-upload:progress", event => {
      const { progress } = event.detail
      this._updateProgress(progress)
    })
    addEventListener("direct-upload:error", event => {
      event.preventDefault()
      this._toggleProgress(false)
      alert("Sorry, that file could not be uploaded.")
    })
  }

  _submitForm() {
    this._resetCounters()
    this._toggleProgress(true)
    this.submitTarget.click()
  }

  _resetCounters() {
    this.fileCount = 0
    this.uploadingCount = 0
    this.uploadedCount = 0
  }

  _toggleProgress(show) {
    if (show) {
      this.buttonTarget.classList.add("d-none")
      this.progressTarget.classList.remove("d-none")
    } else {
      this.progressTarget.classList.add("d-none")
      this.buttonTarget.classList.remove("d-none")
    }
  }

  _updateProgress(value) {
    const totalProgress = (100 * (this.uploadedCount / this.fileCount))
      + (value / this.fileCount)
    const width = `${totalProgress.toFixed()}%`
    this.progressBarTarget.style.width = width
  }

}

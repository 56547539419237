import Tooltip from 'bootstrap/js/dist/tooltip';

// Docs: Tooltips are opt-in for performance reasons, so you must initialize them yourself.

function initTooltips() {
  // Populate an array of elements that should display tooltips
  // Note that in Bootstrap v5 the recommended data attribute is bs-toggle to avoid conflicts with other libraries.
  const tooltipTriggerElems = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));

  // Initialize a tooltip for each element
  tooltipTriggerElems.forEach((tooltipTriggerElem) => {
    new Tooltip(tooltipTriggerElem);
  });
}

export default initTooltips;

import { Controller } from '@hotwired/stimulus';
import { cloneDeep } from 'lodash';
import { FetchRequest } from '@rails/request.js'

// https://www.chartjs.org/docs/latest/

import {
  Chart,
  // ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  // BubbleController,
  // DoughnutController,
  LineController,
  // PieController,
  // PolarAreaController,
  // RadarController,
  // ScatterController,
  CategoryScale,
  LinearScale,
  // LogarithmicScale,
  // RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  // Decimation,
  // Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';

Chart.register(
  // ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  // BubbleController,
  // DoughnutController,
  LineController,
  // PieController,
  // PolarAreaController,
  // RadarController,
  // ScatterController,
  CategoryScale,
  LinearScale,
  // LogarithmicScale,
  // RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  // Decimation,
  // Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

Chart.defaults.font.size = 9;
Chart.defaults.font.weight = '400';
Chart.defaults.font.family = "'Mulish', sans-serif;"
Chart.defaults.color = '#707070';

const CHART_COLORS = {
  red: '#db0d0d',
  orange: '#FC6A01',
  yellow: '#FED900',
  green: '#0BA40A',
};

const netIncomeOptions = {
  responsive: true,
  aspectRatio: 3,
  elements: {
    // These options (somehow) add rounded ends to the line
    point: {
      radius: 1,
      pointStyle: 'star',
      hoverRadius: 1, // Ensure we don't show a star when hovered
      hitRadius: 15,  // Use a larger area to initate the hover
    }
  },
  plugins: {
    title: {
      display: true,
      text: 'NET INCOME', // Uppercase because we don't have control over the case
      font: {
        size: 14,
        weight: '600',
        family: "'Poppins', sans-serif"
      },
      padding: {
        bottom: 8
      }
    },
    legend: {
      display: false
    },
    tooltip: {
      titleSpacing: 10,
      displayColors: false, // Hide the color box
      callbacks: { label: (data) => `${formatCash(data.raw)}` },
      cornerRadius: 3,
    }
  },

  scales: {
    x: {
      grid: {
        display: false, // Don't display vertical grid lines
        drawBorder: false, // Don't draw a botton border line
        color: '#E0E0E0',
      },
      ticks: {
        padding: 4,
      },
    },
    y: {
      grid: {
        drawBorder: false, // Don't draw a vertical border line
        // Draw the y-axis zero line thicker than the others
        lineWidth: context => context.tick.value === 0 ? 3 : 1,
      },

      // Format the ticks to:
      // - include a dollar sign
      // - Be formatted as ###K e.g. 100K
      ticks: {
        callback: (value, _index, _values) => `${formatCash(value)}`,
      }
    }
  }
}

// For the second chart's settings we'll (deep) clone the main chart's
// settings and update where we need to
const revenueExpensesOptions = cloneDeep(netIncomeOptions);
revenueExpensesOptions.aspectRatio = 5
revenueExpensesOptions.scales.y.grid.lineWidth = 1
revenueExpensesOptions.plugins.title.text = 'REVENUE & EXPENSES'
revenueExpensesOptions.plugins.title.color = '#267dbf'
revenueExpensesOptions.plugins.legend = {
  display: true,
  position: 'bottom',
  labels: {
    boxWidth: 10,
    boxHeight: 10,
    generateLabels: () => {
      return [{
        text: 'REVENUE',
        fillStyle: '#267dbf',
        strokeStyle: 'white',
        fontColor: '#267dbf',
      }, {
        text: 'EXPENSES',
        fillStyle: '#000000',
        strokeStyle: 'white',
        fontColor: '#000000',
      }]
    }
  }
}

// We'll use a constant height. I couldn't find a way to specify a
// minimum height. It looks okay.
revenueExpensesOptions.maintainAspectRatio = false

// Format e.g.  180000 as 180K
// Format e.g. -23000  as -23K
function formatCash(n) {
  const absoluteValue = Math.abs(n);
  const symbol = (Math.sign(n) === -1) ? '-' : '';
  if (absoluteValue < 1e3) return `${symbol}$${absoluteValue}`;
  if (absoluteValue >= 1e3) return `${symbol}$` + +(absoluteValue / 1e3).toFixed(1) + "K";
};

// Customize the color of the line so that it:
// - Trends towards green when the value is positive
// - Is aellow when around zero
// - Trends towards red when the value is negative

function getBorderColor(context) {
  // This case happens on initial chart load
  if (!context.chart.chartArea) return null;

  let width, height, gradient;
  const { chart } = context;
  const { chartArea } = chart;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  let gradientFill;

  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    const originPos = chart.scales.y.getPixelForValue(0);
    const zeroRatio = originPos / chartHeight;

    // The top of the chart is always green
    gradientFill = chart.ctx.createLinearGradient(0, 0, 0, chartHeight);
    gradientFill.addColorStop(0, CHART_COLORS.green);

    if (zeroRatio > 1) {
      // If the chart is all positive values, set the bottom colour to yellow
      gradientFill.addColorStop(1, CHART_COLORS.yellow);
    } else {
      // Otherwise set the zero line to yellow and all negative values to red
      gradientFill.addColorStop(zeroRatio, CHART_COLORS.yellow);
      gradientFill.addColorStop(1, CHART_COLORS.red);
    }
  }

  return gradientFill;
}

// When rendering the x axis, show the year for:
//  - the first item
//  - january
function getLabels(json) {
  return json.data.map((year_month, index) => {
    let label = year_month.monthAbbr;
    if (year_month.month == 1 || index === 0) {
      label = `${year_month.monthAbbr} '${year_month.year.toString().slice(-2)}`
    }
    return label.toUpperCase();
  })
}

export default class extends Controller {
  static targets = ['netIncomeChart', 'revenueExpensesChart']

  async connect() {
    const json = await this.fetchChartData(this.netIncomeChartTarget.dataset.url);
    this.drawNetIncomeChart(this.netIncomeChartTarget, json);
    this.drawRevenueExpensesChart(this.revenueExpensesChartTarget, json);
  }

  async fetchChartData(url) {
    const request = new FetchRequest(
      'GET',
      url, {
      headers: { 'Content-Type': 'application/json' }
    }
    )
    const response = await request.perform()
    return response.json
  }

  drawNetIncomeChart(target, json) {
    new Chart(target, {
      type: 'line',
      data: {
        labels: getLabels(json),
        datasets: [
          {
            type: 'line',
            data: json.data.map((year_month) => year_month.netIncomeAmount),
            labels: json.data.map((year_month) => year_month.netIncomeLabel),
            borderJoinStyle: 'round',

            // Use an 'angular' line
            lineTension: 0,

            // Specify the line width
            borderWidth: 6,

            // Use a gradient for the line
            borderColor: getBorderColor,

            // Fill the area above and below the origin with a solid color.
            // Enable the Filler import to use this.
            // fill: {
            //   target: 'origin',
            //   above: CHART_COLORS.green,
            //   below: CHART_COLORS.red,
            // },
          },
        ],
      },
      options: netIncomeOptions,
      data_type: 'chart',
    });
  }

  drawRevenueExpensesChart(target, json) {
    new Chart(target, {
      type: 'bar',
      data: {
        labels: getLabels(json),
        datasets: [
          {
            label: 'Revenue',
            type: 'bar',
            data: json.data.map((year_month) => year_month.revenueAmount),
            labels: json.data.map((year_month) => year_month.revenueLabel),
            backgroundColor: '#267dbf',
            barThickness: 10,
            borderWidth: 1,
            borderColor: '#FFFFFF',
            fill: false,
          },
          {
            label: 'Expenses',
            type: 'bar',
            data: json.data.map((year_month) => year_month.expenseAmount),
            labels: json.data.map((year_month) => year_month.expenseLabel),
            backgroundColor: '#000000',
            barThickness: 10,
            borderWidth: 1,
            borderColor: '#FFFFFF',
            fill: false,
          },
        ],
      },
      options: revenueExpensesOptions,
      data_type: 'chart',
    });
  }
}
